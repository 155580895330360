:root {
    --primary: #0039C1;
    --secondary: #666666;
    --dark: #333;
    --black: #000;
    --light: #F0F6F8;
    --white: #fff;
    --ff-ss: 'univia-pro', sans-serif;
    --ff-bdy: 'Roboto', sans-serif;
    --reading: 78ch;
    --fs-base: 1rem;
    --green: #00B259;
    --yellow: #FFC012;
    --danger:#EC1C24;
}
