
h6 {
    color: #0039c1;
}

.button-container {
    display: flex;
    justify-content: space-between;
}

table-cell{
    padding:0px !important;
}

h4 {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2rem;
}

.hideBlock{
    display:none;
}

.displayBlock{
    display:block;
}

.info {
    display: inline-block;
    padding: 0px 4px 5px 3px;
    font-size: 12px;
    border-radius: 50%;
    border: solid 2px;
    width: 20px;
    height: 20px;
    font-weight: bold;
    text-align: center;
    margin-left: 1%;
}
